import styled from "styled-components";

export const Row = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
  li {
    display: inline-block;
  }
`;
